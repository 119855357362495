<template>
  <div class="resentities">
    <div class="resentities__wrap">
      <BlockSubtitle>{{$t('common.entities')}}</BlockSubtitle>
      <div class="resentities__list">

        <div
          v-for="(entity, i) in paginatedResults(results)"
          :key="i"
          class="resentities__item"
          @click="goToEntity(entity)">
          <div class="resentities__logo">
            <img v-if="entity.logo" :src="entity.logo" :alt="entity.name">
            <img v-else src="/img/icons/entities/nologo.png" :alt="entity.name">
          </div>
          <div class="resentities__name">{{entity.acronim}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UrlService from '@/services/url/UrlService';

const urlService = new UrlService();

export default {
  name: 'ResultsEntitiesComponent',
  data() {
    return {
      current_page: 1,
      page_size: 100,
    };
  },
  props: {
    results: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  methods: {
    filterResults(results) {
      return results;
    },
    paginatedResults(results) {
      return this.filterResults(results)
        .slice((this.current_page - 1) * this.page_size, this.current_page * this.page_size);
    },
    getPagesLength(results) {
      const totalPages = Math.ceil(this.filterResults(results).length / this.page_size);
      return Array(totalPages).fill().map((_, idx) => idx + 1);
    },
    goToEntity(entity) {
      const route = this.$router.resolve(urlService.entityRoute(entity, this.$i18n.locale));
      window.open(route.href, '_blank');
    },
  },
};
</script>

<style lang="scss">
.resentities {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    flex: 1 1 100%;
    background: var(--background-color-bluish);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border-bottom: 3px solid var(--button-box-shadow-color-default);
    text-align: center;
    cursor: pointer;
    img {
      max-height: 60px;
      width: auto;
      margin: 0 auto 12px;
      display: block;
    }
  }
  &__pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  &__page {
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-left: 6px;
    background: var(--background-color-darker);
    text-align: center;
    font-size: 12px;
    cursor: pointer;

    &.is-current {
      background: var(--background-color-inverted);
      color: var(--text-color-inverted);
    }
  }
}

@media screen and (min-width: 780px) {
  .resentities {
    &__list {
      width: calc(100% + 10px);
    }
    &__item {
      flex: 0 0 calc(33.33% - 10px);
      box-sizing: border-box;
      margin-right: 10px;
      padding: 16px;
    }
  }
}
</style>
