<template>
  <Page :classes="['page--entities']">

    <SearchComponent
      @search="onSearch"
      :showCategory="true"
      :showPhase="false"
      :showChallenge="true"
      :showType="true"
      :showSector="true"
      :showAdm1="true"
      :showNameSearch="true"
      :showSearchButton="true"
      :showHomeButton="true"
    ></SearchComponent>

    <div class="spinner__wrap">
      <Spinner
        v-if="isLoading"
        color="#2a2f45"
        :stroke="3"
        :size="50"></Spinner>
    </div>

    <ResultsCount
      v-if="!isLoading"
      :results="entities.length"></ResultsCount>

    <ResultsEntitiesComponent
      v-if="!isLoading"
      :results="entities"></ResultsEntitiesComponent>

  </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import UrlService from '@/services/url/UrlService';
import HttpService from '@/services/http/HttpService';
import SearchComponent from '@/components/search/SearchComponent.vue';
import ResultsCount from '@/components/results/count/ResultsCount.vue';
import ResultsEntitiesComponent from '@/components/results/entities/ResultsEntitiesComponent.vue';

const urlService = new UrlService();

export default {
  name: 'EntitiesPage',
  components: {
    SearchComponent,
    ResultsCount,
    ResultsEntitiesComponent,
  },
  data() {
    return {
      isLoading: false,
      source: undefined,
    };
  },
  computed: {
    ...mapGetters(['self', 'entities']),
  },
  mounted() {
    if (this.isEmptySearch()) this.onSearch({});
  },
  methods: {
    async onSearch(filters) {
      this.isLoading = true;
      if (this.source) {
        await this.source.cancel('Cancel previous request');
      }
      const { CancelToken } = HttpService;
      this.source = CancelToken.source();
      await this.search(filters);
      this.isLoading = false;
    },
    async search(filters) {
      if (Object.keys(filters).length > 0) {
        await this.getEntities(filters);
      } else {
        await this.getEntities({});
      }
    },
    async getEntities(params) {
      await this.$store.dispatch('list', {
        model: 'entity',
        params,
        updateModel: true,
        cancelToken: this.source.token,
      });
    },
    isEmptySearch() {
      return urlService.getQueryString(window.location.href) === null;
    },
  },
};
</script>

<style lang="scss">
.page--entities {
  max-width: initial !important;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 !important;
  .page__main {
    overflow: auto;
  }
  .page__body {
    overflow: auto;
  }
  .claim,
  .searchcom {
    padding: 20px 10px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  .rescount {
    padding: 20px 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    max-width: 600px;
  }
  .resentities,
  .resran {
    padding: 0 20px;
    max-width: var(--layout-max-width-default);
    margin: 0 auto;
  }
}
@media screen and (min-width: 1200px) {
  .page--home {
    .claim,
    .searchcom,
    .resran {
      max-width: var(--layout-max-width-narrow);
    }
  }
}
</style>
